import './Body.css';
import Footer from "./Footer.js";
import Header from "./Header.js";
import Home from "./elements/Home.js";
import Brunch from "./elements/Brunch.js";
import Marketing from "./elements/Marketing.js";
import Contact from "./elements/Contact.js";
import byteLogo from '../assets/images/byteLogo.png'
import React, {useState} from "react";

function Body (props) {
    const [activeTab, setActiveTab] = useState("home")
    const [showFooter, setShowFooter] = useState(false)

    const pageMap= {
        "home": <Home/>,
        "brunch": <Brunch/>,
        "marketing": <Marketing/>,
        "contact": <Contact/>
    }
    
    return (
<div>
    <Header isMobile={props.isMobile} activeTab={activeTab} setActiveTab={setActiveTab}/>
    {/* <Content/> */}
    {pageMap[activeTab]}
    {showFooter && 
        <Footer setShowFooter={setShowFooter} isMobile={props.isMobile} activeTab={activeTab} setActiveTab={setActiveTab}/>
    }
    {!showFooter && 
        <img onClick={() => setShowFooter(true)} className="hiddenFooterByteLogo" src={byteLogo} alt="byte icon, a robot chef wearing a teal blue chefs hat with some orange accents on his face"/>
    }
</div>

    )

}

export default Body;