import './Elements.css';
import React from "react";

function Contact(props) {

    return (
    <div className="elementBody">
                <div className="elementLargeBlock">
            <p className="elementHeader"> restaurant owners: </p>
            <p className="elementBody"> contact us at sales@byte-smarterkitchen.com </p>
        </div> 
    </div>
    )
}
export default Contact;