import './Elements.css';
import React from "react";

function Home(props) {

    return (
    <div className="elementBody">
        <div className="elementLargeBlock">
            <p className="elementHeader"> revitalizing restaurants with smarter software </p>
            <p className="elementBody"> our ai powered restaurant software is designed to reduce food waste, improve customer satisfaction, streamline employee training, and boost employee retention.</p>
            <p className="elementBody"> from casual eateries to fine dining, we empower your restaurant with software tools to thrive in an ever changing industry.</p>
        </div> 
    </div>
    )
}
export default Home;