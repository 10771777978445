import './Elements.css';
import React from "react";

function Marketing(props) {

    return (
    <div className="elementBody">
        <div className="elementLargeBlock">
            <p className="elementHeader"> engage customers with interactive experiences</p>
            <p className="elementBody"> improve your guest relations with our organic marketing technology, which strengthens connections with the guests in your store and helps you to reach new, interested guests </p>
            <p className="elementBody"> our most popular feature, the e-photobooths, allows you to set up a customized electronic photobooth for guests to interact with, directly on their phones, with easy social media sharing  </p>
        </div> 
    </div>
    )
}
export default Marketing;