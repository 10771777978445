import './Elements.css';
import React from "react";

function Brunch(props) {

    return (
    <div className="elementBody">
        <div className="elementLargeBlock">
            <p className="elementHeader"> elevate your bottomless brunch experience</p>
            <p className="elementBody"> does your restaurant offer bottomless brunch? </p>
            <p className="elementBody"> our new bottomless brunch feature works to seamlessly manage time limits, track customer orders, and optimize the flow of service to keep glasses full and guests delighted. Whether it's mimosas, bellinis, or a specialty cocktail, our system ensures a smooth, stress free brunch experience for your staff and customers alike. </p>
        </div> 
    </div>
    )
}
export default Brunch;